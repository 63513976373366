import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  
`;

export const TextH1 = styled.h1`
  color: var(--white);
  font-size: 78px;
  font-weight: 500;
`;

export const TextH2 = styled.h2`
  color: var(--white);
  font-size: 58px;
  font-weight: 500;
`;

export const TextH3 = styled.h3`
  color: var(--white);
  font-size: 38px;
  font-weight: 500;
`;

export const TextTitle = styled.p`
  color: var(--white);
  font-size: 20px;
  font-weight: 500;
`;

export const TextSubTitle = styled.p`
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
`;

export const TextDescription = styled.p`
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const ResponsiveWrapper = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const StyledButton = styled.div`
  
`;