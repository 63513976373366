import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import i1 from "./assets/images/1.png";
//import "./bulma.min.css";
import "./custom.css";
var Web3 = require('web3');

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 100px;
  border: none;
  background-color: #6fff4e;
  font-weight: bold;
  color: #000000;
  width: 200px;
  cursor: pointer;
`;

export const ResponsiveWrapper = styled.div`

`;

export const StyledImg = styled.img`
  width: 200px;
  height: 200px;
  @media (min-width: 767px) {
    width: 350px;
    height: 350px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Click the button below to adopt your dog!");
  const [claimingNft, setClaimingNft] = useState(false);
  
  const claimNFTs = (_amount) => {

    if (_amount <= 0) {
      return;
    }

    setFeedback("Minting your Dank Dog...");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((0.04 * _amount).toString(), "ether"),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null, 
        //gasPrice: 4000000,
        gas: 240000,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "OMG, you've now adopted a Dank Dog - go visit Opensea.io to view it."
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (

    
              
    <div>
   
    
    <div>
      {Number(data.totalSupply) == 8000 ? (
        <>
          <s.TextTitle style={{ textAlign: "center" }}>
            The sale has ended.
          </s.TextTitle>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center" }}>
            You can still find Dank Dogz on{" "}
            <a
              target={"_blank"}
              href={"https://opensea.io/collection/secret-society-of-dank-dogz/"}
            >
              Opensea.io
            </a>
          </s.TextDescription>
        </>
      ) : (
        <>
          
          <h2 class="has-text-align-center">Catch your dog now for 0.04 ETH</h2>
          <br />
          <p class="has-text-align-center">The shadow rules decree that only <strong>1 dog</strong> can be adopted per person. Ownership of your dog is 💯 - you have full rights to your dog and how you use him or her.</p>
          <br />
          <h4 class="has-text-align-center">
            {feedback}
          </h4>
          <br />
          {blockchain.account === "" ||
          blockchain.smartContract === null ? (
            <div class="wp-block-buttons alignfull aligncenter">
              
              <div class="wp-block-button aligncenter is-style-fill">
                <a class="wp-block-button__link has-black-color has-text-color has-background"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}
                >
                  CONNECT YOUR WALLET
                </a>
              </div>



              {blockchain.errorMsg !== "" ? (
                <>
                  <br />
                  <h4 style={{ textAlign: "center" }} class="has-text-align-center">
                    {blockchain.errorMsg}
                  </h4>
                </>
              ) : null}
            </div>
          ) : (
            <div class="wp-block-buttons alignfull aligncenter">
              
              <div class="wp-block-button aligncenter is-style-fill">
                <a class="wp-block-button__link has-black-color has-text-color has-background" id="button1"
                disabled={claimingNft ? 1 : 0}
                onClick={(e) => {
                  e.preventDefault();
                  claimNFTs(1);
                }}
                >
                  {claimingNft ? "BUSY ADOPTING" : "ADOPT 1 DOG"}
                </a>
              </div>

              
              </div>

          )}
        </>
      )}
    </div>
  </div>
    
  );
}

export default App;
